<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Cargar archivo
                        <v-spacer></v-spacer>
                        <v-btn href="/storage/carga_masiva_de_ordenes.xlsx" target="_blank" class="ma-2" tile text color="info">
                            <v-icon left>icon-file_dowload</v-icon> Descargar archivo formato excel
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-file-input
                        v-model="files"
                        color="deep-purple accent-4"
                        counter
                        multiple
                        placeholder="Selecciona un archivo"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        >
                            <template v-slot:selection="{ index, text }">
                                <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                                >
                                {{ text }}
                                </v-chip>

                                <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                                >
                                +{{ files.length - 2 }} File(s)
                                </span>
                            </template>
                        </v-file-input>
                        
                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <v-btn
                                :loading="loading"
                                :disabled="loading"
                                
                                class="ma-2 white--text btn-starkoms-primary"
                                @click="sendForm"
                            >
                                Subir
                                <v-icon right dark>mdi-cloud-upload</v-icon>
                                </v-btn>
                            </div>
                            <div class="col-12 col-sm-4 text-right disclaimer">
                                <v-checkbox   v-model="validate_sku" label="Validar stock y existencia de los productos" > Validar stock y existencia de los productos </v-checkbox>

                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
export default {
    data(){
        return {
            files: [],
            loading: false,
            validate_sku: false,
        }
    },
    methods: {
        sendForm() {   
            this.loading = true;
            this.$nextTick(() => {
                if(this.files.length == 1)
                {
                    let formData = new FormData();
                    // files
                    formData.append("file", this.files[0], this.files[0].name);
                    console.log(this.validate_sku);
                    if(this.validate_sku){
                        formData.append("validate_sku",1);
                    }
                    var vm = this;
                    this.axios({
                        url: 'orders/bulk_load',
                        data: formData,
                        method: 'POST'
                    }).then( response => {
                        vm.loading = false;
                        vm.$bvToast.toast(
                            'Fueron agregados con exito: '+response.data.nro_pedidos+' Pedidos y '+response.data.nro_productos+' productos!',
                            {
                                title: `Notificación`,
                                variant: "success",
                                solid: true,
                                toaster: "b-toaster-bottom-center",
                            }
                        );
                    }).catch( error => {
                        vm.loading = false;
                        const response = error.response.data;
                        const response_obj = JSON.parse(response);
                        if (response_obj.error) {

                            vm.$bvToast.toast(
                                response_obj.message,
                                {
                                    title: `Notificación`,
                                    variant: "danger",
                                    solid: true,
                                    toaster: "b-toaster-bottom-center",
                                }
                            );
                        }
                    });
                }
                else{
                    this.loading = false;
                    console.log('no hay archivo');
                }
            })
                
        }
    },
}
</script>
<style scoped>
/* .disclaimer label{
    font-size: 12px;
    color: #666;
} */
</style>